import React, { useEffect, useMemo, useState } from 'react';
import { matchSorter } from 'match-sorter';
import { debounce, TextField, Theme } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';

import { ApiFilterCriteria } from '../../types';
import FilterPopover from './FilterPopover';
import {
  FilterColumnConfig,
  FilterColumnOption,
  FilterColumnOptionCallback,
  FilterColumnOptionSearchCallback,
} from './types';

interface AutoCompleteFilterProps {
  field: string;
  name: string;
  onChange: (options: FilterColumnOption[]) => void;
  criteria: ApiFilterCriteria;
  options?: FilterColumnOption[] | FilterColumnOptionCallback;
  searchOptions?: FilterColumnOptionSearchCallback;
  config?: FilterColumnConfig;
  showLabel?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    display: 'inline-flex',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  name: {
    display: 'inline-block',
    marginRight: theme.spacing(1),
  },
  autoComplete: {
    width: 250,
    margin: theme.spacing(2),
  },
}));

const AutoCompleteFilter = (props: AutoCompleteFilterProps) => {
  const { field, name, config, onChange, criteria, showLabel } = props;
  const classes = useStyles();
  const [options, setOptions] = useState<FilterColumnOption[]>([]);
  const [filters, setFilters] = useState<{ [key: string]: boolean }>({});
  const [inputValue, setInputValue] = useState<string>('');
  const [currentCriteria, setCurrentCriteria] =
    useState<ApiFilterCriteria | null>(null);

  const noFilters: { [key: string]: boolean } = useMemo(() => ({}), []);

  const selectedOptions = useMemo(
    () => options.filter((option) => filters[option.value] || false),
    [filters],
  );

  const fetchOptions = async () => {
    if (props.options && typeof props.options === 'function') {
      const options = await props.options();
      setOptions(options);
    }

    if (props.searchOptions) {
      const newOptions = await props.searchOptions(inputValue);
      setOptions([...selectedOptions, ...newOptions]);
    }
  };

  const handleAutocompleteChange = (event: any, options: any) => {
    const newFilters: {
      [key: string]: boolean;
    } = {};

    options.forEach((option: FilterColumnOption) => {
      newFilters[option.value] = true;
    });

    setFilters(newFilters);
  };

  const handleAutocompleteInputChange = debounce(
    (event, newInputValue) => setInputValue(newInputValue),
    300,
  );

  useEffect(() => {
    if (options.length === 0) {
      return;
    }

    onChange(options.filter((option) => filters[option.value] || false));
  }, [options, filters]);

  useEffect(() => {
    fetchOptions();
  }, [inputValue]);

  /**
   * Handle initial load.
   */
  useEffect(() => {
    // If the criteria did not change, don't run update.
    if (
      criteria !== null &&
      JSON.stringify(criteria) === JSON.stringify(currentCriteria)
    ) {
      return;
    }

    if (props.options && typeof props.options !== 'function') {
      setOptions(props.options);
    }

    if (
      criteria.filters &&
      (props.options !== undefined || props.searchOptions !== undefined)
    ) {
      const fieldFilter = criteria.filters[field];

      if (
        (typeof fieldFilter === 'string' || Array.isArray(fieldFilter)) &&
        fieldFilter.length > 0
      ) {
        const newFilters: { [key: string]: boolean } = {};

        if (Array.isArray(fieldFilter)) {
          const currentOptionValues = options.map((o) => o.value);

          // make sure that currently selected options are always in the set of options
          setOptions([
            ...options,
            ...fieldFilter.filter(
              (f) => !currentOptionValues.includes(f.value),
            ),
          ]);
          fieldFilter.forEach(({ value }) => {
            newFilters[value] = true;
          });
        } else {
          fieldFilter.split(',').forEach((id) => {
            newFilters[id] = true;
          });
        }

        setFilters(newFilters);
      } else {
        setFilters(noFilters);
      }
    }

    setCurrentCriteria(criteria);
  }, [criteria]);

  const hasFilters = useMemo(() => {
    return Object.entries(filters).length > 0;
  }, [filters]);

  const filterOptions = (
    options: FilterColumnOption[],
    { inputValue }: { inputValue: string },
  ) =>
    matchSorter<FilterColumnOption>(options, inputValue, {
      keys: ['label', 'metadata'],
    });

  return (
    <FilterPopover name={name} showLabel={showLabel} hasFilters={hasFilters}>
      <Autocomplete
        id={`filter-column-${field}`}
        getOptionLabel={(option) => option.label}
        options={options}
        onChange={handleAutocompleteChange}
        noOptionsText="Geen opties beschikbaar."
        value={selectedOptions}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant="standard"
            label={config?.autoCompleteLabel || ''}
            placeholder={config?.autoCompletePlaceholder || ''}
          />
        )}
        onInputChange={handleAutocompleteInputChange}
        filterOptions={filterOptions}
        className={classes.autoComplete}
        multiple
        autoComplete
      />
    </FilterPopover>
  );
};

export default AutoCompleteFilter;
