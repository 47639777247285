import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  TextField,
  CircularProgress,
} from '@material-ui/core';

import moment from 'moment';
import { useDispatch } from 'react-redux';
import EmailListBuilder from '../../../components/EmailListBuilder';
import { Assignment, AssignmentAccessToken } from '../../../types';
import AssignmentRepository from './repository/AssignmentRepository';
import DialogCloseButton from '../../../components/DialogCloseButton';
import { refreshAccount } from '../../../actions';
import EducationContext from '../EducationContext';
import { getReadableAccessTokenStatus } from './helpers';

interface ExternalInviteDialogProps {
  assignment: Assignment;
  open: boolean;
  onClose: () => void;
  onSubmit?: () => void;
}

const ExternalInviteDialog = (props: ExternalInviteDialogProps) => {
  const { assignment, open, onClose, onSubmit } = props;
  const notifications = useSnackbar();
  const dispatch = useDispatch();
  const [accessTokens, setAccessTokens] = useState<AssignmentAccessToken[]>([]);
  const [addresses, setAddresses] = useState<string[]>([]);
  const [explanation, setExplanation] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { educationPermissions } = useContext(EducationContext);

  const handleExplanationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => setExplanation(event.target.value);

  const loadAccessTokens = useCallback(() => {
    new AssignmentRepository()
      .getAccessTokens(assignment.id)
      .then((response) => {
        setAccessTokens(response.data);
        setLoading(false);
      });
  }, [assignment.id]);

  const handleSubmit = async () => {
    if (addresses.length === 0) {
      return;
    }

    setLoading(true);

    try {
      await new AssignmentRepository().createAccessToken(
        assignment,
        addresses,
        explanation,
      );
    } catch (e) {
      if (addresses.length > 1) {
        notifications.enqueueSnackbar('Fout bij versturen uitnodiging!', {
          variant: 'error',
        });
      } else {
        notifications.enqueueSnackbar('Fout bij versturen uitnodigingen!', {
          variant: 'error',
        });
      }

      return;
    }

    dispatch(
      refreshAccount(() => {
        if (addresses.length > 1) {
          notifications.enqueueSnackbar('De uitnoding is verstuurd.', {
            variant: 'success',
          });
        } else {
          notifications.enqueueSnackbar('De uitnodingen zijn verstuurd', {
            variant: 'success',
          });
        }

        if (onSubmit) {
          onSubmit();
        }

        loadAccessTokens();
        setAddresses([]);
      }),
    );
  };

  const handleEmailListChange = (addresses: string[]) =>
    setAddresses(addresses);

  useEffect(() => {
    if (!open) {
      return;
    }

    loadAccessTokens();
  }, [open, loadAccessTokens]);

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>
        Verzoek tot bijdrage
        <DialogCloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        {accessTokens.length > 0 && (
          <Box mb={2}>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {accessTokens.map((token) => (
                    <TableRow key={token.id}>
                      <TableCell>
                        {`${token.email} (${moment(token.created).format(
                          'YYYY-MM-DD HH:mm',
                        )})`}
                      </TableCell>
                      <TableCell>
                        {getReadableAccessTokenStatus(token)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
        {assignment && assignment.editable && educationPermissions.edit && (
          <EmailListBuilder
            addresses={addresses}
            onChange={handleEmailListChange}
          >
            <Box mb={2}>
              <Typography variant="body2">
                Wil je dat iemand een bijdrage levert aan deze opdracht?
                <p>
                  Dan kun je door middel van het opgeven van een mail adres deze
                  persoon een link aanbieden waarna hij/zij de velden invult en
                  het daarna terugkomt bij jou als deelnemer. Je ontvangt
                  hiervan een notificatie. Let op: wanneer je dit verzoek naar
                  meedere mensen stuurt werken zij in hetzelfde formulier en
                  zien elkaars feedback.
                </p>
                <p>Jij levert het daarna ter beoordeling in bij je opleider.</p>
              </Typography>
              <Box mt={1} overflow="hidden">
                <FormLabel>
                  Toelichting toevoegen aan uitnodiging (optioneel):
                </FormLabel>
                <Box mt={1}>
                  <TextField
                    id="external-invite-explanation"
                    placeholder="Geef hier je toelichting..."
                    multiline
                    fullWidth
                    minRows={2}
                    variant="outlined"
                    onChange={handleExplanationChange}
                  />
                </Box>
              </Box>
            </Box>
          </EmailListBuilder>
        )}
      </DialogContent>
      {assignment && assignment.editable && educationPermissions.edit && (
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color="secondary"
            variant="contained"
            disabled={addresses.length === 0}
            startIcon={loading ? <CircularProgress size={14} /> : null}
          >
            Uitnodiging versturen
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ExternalInviteDialog;
