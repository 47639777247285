import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import { AxiosResponse } from 'axios';
import { Assignment } from '../../../types';
import FormEntry from '../../yard-forms/FormEntry';
import AssignmentRepository from '../assignment/repository/AssignmentRepository';
import AssignmentEvaluations from '../../evaluation/AssignmentEvaluation';
import EPALevelSelector from '../../evaluation/EPALevelSelector';
import AssignmentDialog from '../assignment/AssignmentDialog';
import DialogCloseButton from '../../../components/DialogCloseButton';
import { DialogMessage } from '../assignment/AssignmentDialogMessage';
import AssignmentMessageRepository from '../assignment/repository/AssignmentMessageRepository';

interface ProductViewDialogProps {
  product: Assignment;
  onClose: () => void;
  open: boolean;
}

const ProductViewDialog = (props: ProductViewDialogProps) => {
  const { onClose, open, product } = props;
  const notifications = useSnackbar();
  const token = new URLSearchParams(window.location.search).get('token');
  const repository = new AssignmentRepository(token);
  const [assignment, setAssignment] = useState<Assignment | null>(null);

  const createNewMessage = async (
    content: string,
  ): Promise<AxiosResponse<DialogMessage>> =>
    new AssignmentMessageRepository().create(assignment!!.id, content);

  const handleNewMessage = (message: string) => {
    if (!assignment) {
      return;
    }

    createNewMessage(message).then((response) => {
      setAssignment({
        ...assignment,
        messages: [...assignment.messages, response.data],
      });
    });
  };

  const handleDeleteMessage = (message: DialogMessage) => {
    if (!assignment) {
      return;
    }

    const newMessages = [...assignment.messages];
    newMessages.splice(
      newMessages.findIndex((m) => m.id === message.id),
      1,
    );

    new AssignmentMessageRepository()
      .delete(assignment.id, message.id)
      .then(() => {
        setAssignment({ ...assignment, messages: newMessages });
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Fout bij het verwijderen van het bericht.',
          { variant: 'error' },
        );
      });
  };

  const handleEditMessage = (message: DialogMessage, newContent: string) => {
    if (!assignment) {
      return;
    }

    const newMessages = [...assignment.messages];
    const messageIndex = newMessages.findIndex((m) => m.id === message.id);

    if (!messageIndex) {
      return;
    }

    const oldContent = newMessages[messageIndex].content;
    newMessages[messageIndex].content = newContent;

    new AssignmentMessageRepository()
      .update(assignment.id, message.id, newContent)
      .then(() => {
        setAssignment({ ...assignment, messages: newMessages });
      })
      .catch(() => {
        notifications.enqueueSnackbar('Fout bij het bewerken van het bericht', {
          variant: 'error',
        });

        // Revert message.
        newMessages[messageIndex].content = oldContent;
        setAssignment({ ...assignment, messages: newMessages });
      });
  };

  useEffect(() => {
    repository
      .find(product.id)
      .then((response) => {
        setAssignment(response.data);
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het openen van de opdracht!',
          { variant: 'error' },
        );
      });
  }, [product]);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="professional-activities-form"
      open={open && Boolean(assignment)}
      maxWidth="lg"
    >
      <DialogTitle id="professional-activities-form">
        {assignment && assignment.name}
        <DialogCloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        {assignment && assignment.form && (
          <FormEntry form={assignment.form} entries={assignment.entries} />
        )}
        {assignment && 'assignedLevel' in assignment && (
          <EPALevelSelector assignment={assignment} disabled />
        )}
        {assignment && (
          <AssignmentDialog
            assignment={assignment}
            onNewMessage={handleNewMessage}
            onEdit={handleEditMessage}
            onDelete={handleDeleteMessage}
          />
        )}
        {assignment && assignment.finished && (
          <AssignmentEvaluations assignment={assignment} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ProductViewDialog;
