import React from 'react';

import { Route, Router } from '../../routing';
import MyContributionRequestsOverview from './MyContributionRequestsOverview';

const MY_CONTRIBUTIONS_ROUTE = '/mijn-bijdrageverzoeken';

const myContributions = new Route(
  'Mijn bijdrageverzoeken',
  MY_CONTRIBUTIONS_ROUTE,
  <MyContributionRequestsOverview />,
).requireLogin();
Router.addRoute(myContributions);
