import React, { ReactNode, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Box, IconButton, Popover, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import ConditionalWrapper from '../ConditionalWrapper';

interface FilterPopoverProps {
  name: string;
  showLabel?: boolean;
  hasFilters: boolean;
  children: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    display: 'inline-flex',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  name: {
    display: 'inline-block',
    marginRight: theme.spacing(1),
  },
}));

const FilterPopover = (props: FilterPopoverProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const openMenu = (target: HTMLDivElement) => {
    setAnchorEl(target);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    openMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Box className={classes.column} onClick={handleClick}>
        {props.showLabel && <div className={classes.name}>{props.name}</div>}
        <IconButton size="small">
          <ConditionalWrapper
            condition={props.hasFilters}
            wrapper={(children) => (
              <Badge color="secondary" variant="dot">
                {children}
              </Badge>
            )}
          >
            <FontAwesomeIcon icon={['fal', 'chevron-down']} />
          </ConditionalWrapper>
        </IconButton>
      </Box>
      <Popover
        id={open ? `filter-column-${props.name.toLowerCase()}` : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        elevation={1}
      >
        {props.children}
      </Popover>
    </>
  );
};

export default FilterPopover;
