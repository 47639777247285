import { AxiosResponse } from 'axios';
import {
  ApiFilterCriteria,
  CollectionResponse,
  Education,
  Repository,
  User,
} from '../../types';
import ApiClient from '../../api/ApiClient';
import { parseCriteria } from '../../utils/common';
import { FilterColumnOption } from '../../components/filter-column/types';

class ParticipantsRepository implements Repository<Education> {
  private readonly endpoint: string;

  constructor(educatorId?: string) {
    this.endpoint =
      educatorId !== undefined
        ? `/api/my-participants/educator/${educatorId}`
        : '/api/my-participants';
  }

  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
  ): Promise<CollectionResponse<Education>> {
    const params = parseCriteria(criteria, { page: page || 1 });

    return ApiClient.get(this.endpoint, params);
  }

  getStudents(): Promise<AxiosResponse<User[]>> {
    return ApiClient.get(`${this.endpoint}/students`);
  }

  getEducations(): Promise<AxiosResponse<FilterColumnOption[]>> {
    return ApiClient.get(`${this.endpoint}/educations`);
  }
}

export default ParticipantsRepository;
