import React, { MouseEvent, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Menu,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

import { orange } from '@material-ui/core/colors';
import settings from './config/settings';
import UserImitator from './modules/users/UserImitator';
import UserAuthenticator from './api/UserAuthenticator';
import EducationMenu from './modules/education/EducationMenu';
import Avatar from './modules/users/Avatar';
import MyContributionRequestsIcon from './modules/my-contribution-requests/MyContributionRequestsIcon';
import NotificationBell from './modules/notifications/NotificationBell';
import InfoBell from './modules/info/InfoBell';
import AppContext from './AppContext';
import { ReactComponent as Logo } from './assets/images/logo_nspoh.svg';
import { UserState } from './reducers/user';
import { Education } from './types';
import { colors } from './config/theme';
import { RoleView } from './RoleViewManager';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    background: theme.palette.primary.main,
  },
  appBarTeacher: {
    background: colors.tertiary.main,
    '& $buttonLink:hover': {
      background: colors.tertiary.dark,
    },
  },
  button: {
    alignContent: 'center',
    color: theme.palette.primary.contrastText,
  },
  logo: {
    width: 'auto',
    height: 40,
  },
  menuButton: {
    marginRight: theme.spacing(1),
    fontSize: 23,
  },
  menu: {
    width: 250,
    marginTop: 80,
  },
  menuIcon: {
    marginRight: theme.spacing(2),
  },
  title: {
    marginRight: theme.spacing(4),
  },
  titleLink: {
    color: '#fff',
    '&:hover': {
      color: '#fff',
    },
  },
  buttonLink: {
    fontWeight: 600,
    textTransform: 'none',
    color: theme.palette.primary.contrastText,
    marginRight: theme.spacing(1),
    '&:hover': {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.light,
    },
  },
  container: {
    position: 'relative',
  },
  toolbar: {
    zIndex: 1,
    height: settings.header.height,
    padding: 0,
  },
  toolbarContent: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  toolbarLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbarRight: {},
  toolbarRightGrid: {
    width: 'auto',
  },
  skewedBackground: {
    position: 'absolute',
    zIndex: 0,
    top: 0,
    right: 'calc(100% - 150px)',
    width: '100vw',
    height: '100%',
    background: theme.palette.primary.light,
    transform: 'skew(-15deg)',
    boxShadow: '1px -2px 4px 0px rgba(0, 0, 0, 0.35)',
  },
  profileMenu: {
    zIndex: 1401,
  },
  imitationBar: {
    width: '100%',
    height: 20,
    background: orange[800],
    textAlign: 'center',
    fontSize: 12,
    color: '#FFF',
  },
  link: {
    color: '#FFF',
    textDecoration: 'underline',
    '&:hover': {
      color: '#FFF',
    },
  },
  userName: {
    marginLeft: theme.spacing(2),
    textAlign: 'left',
    lineHeight: '18px',
    textTransform: 'none',
  },
  activeStateBar: {
    position: 'absolute',
    opacity: 0,
    left: 'auto',
    bottom: 0,
    width: 0,
    height: 4,
    background: theme.palette.secondary.main,
    transition: theme.transitions.create(['left', 'width']),
  },
}));

function Navigation() {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { isLoggedIn, isImitating, account, education } = useSelector(
    (selector: { user: UserState; education: Education | null }) => ({
      isLoggedIn: selector.user.isLoggedIn,
      isImitating: Boolean(
        selector.user.account && selector.user.account.isImitating,
      ),
      account: selector.user.account,
      education: selector.education,
    }),
  );
  const dispatch = useDispatch();
  const { roleViewManager, localStore } = useContext(AppContext);

  const furtherEducation =
    account &&
    account.educations &&
    account.educations.find((education) => education.furtherEducation);

  useEffect(() => {
    const activeStateBar = document.querySelector<HTMLDivElement>(
      `.${classes.activeStateBar}`,
    );
    const activeItem = document.querySelector<HTMLLinkElement>(
      `.${classes.toolbar} .active`,
    );

    if (!activeItem) {
      activeStateBar!.style.opacity = '0';
      return;
    }

    activeStateBar!.style.opacity = '1';
    activeStateBar!.style.left = `${activeItem.offsetLeft}px`;
    activeStateBar!.style.width = `${
      activeItem.getBoundingClientRect().width
    }px`;
  }, [location, education]);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = (e: MouseEvent) => {
    e.preventDefault();
    new UserAuthenticator(dispatch).logout(() => {
      handleMenuClose();
      history.push('/');
    });
  };

  const handleMyAccount = () => {
    history.push('/account');
    handleMenuClose();
  };

  const changeView = async (view: RoleView) => {
    await roleViewManager.setCurrentView(view);
    history.push('/');
    handleMenuClose();
  };

  const stopImitating = () => {
    new UserImitator(
      dispatch,
      roleViewManager,
      localStore,
      isImitating,
    ).stopImitating(() => {
      history.push('/gebruikers');
    });
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id="primary-account-menu"
      className={classes.profileMenu}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      style={{ zIndex: 1401, top: 40 }}
      onClose={handleMenuClose}
      elevation={1}
    >
      <MenuItem onClick={handleMyAccount}>Profiel</MenuItem>
      {roleViewManager.getViews().length > 1 &&
        roleViewManager
          .getViews()
          .filter((view) => view !== roleViewManager.getCurrentView())
          .map((view) => (
            <MenuItem key={view} onClick={() => changeView(view)}>
              {`${roleViewManager.getViewLabel(view)} weergave`}
            </MenuItem>
          ))}
      {account && isImitating && (
        <MenuItem onClick={stopImitating}>Stop overnemen</MenuItem>
      )}
      <MenuItem onClick={handleLogout}>Uitloggen</MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={
          roleViewManager.isEducatorView() || roleViewManager.isAdminView()
            ? classes.appBarTeacher
            : classes.appBar
        }
      >
        <Container className={classes.container}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.activeStateBar} />
            <Typography variant="h6" className={classes.title}>
              <Link to="/" className={classes.titleLink}>
                <Logo className={classes.logo} />
              </Link>
            </Typography>
            <Box className={classes.toolbarContent}>
              <Box className={classes.toolbarLeft}>
                {account && isLoggedIn && (
                  <Box>
                    {roleViewManager.isParticipantView() && (
                      <EducationMenu
                        account={account}
                        linkClass={classes.buttonLink}
                      />
                    )}
                    {furtherEducation && roleViewManager.isParticipantView() && (
                      <Button
                        component={Link}
                        to={`/onderwijs/${furtherEducation.id}`}
                        color="inherit"
                        className={`${classes.buttonLink} ${
                          new RegExp(
                            `/onderwijs/${furtherEducation.id}.*?`,
                          ).test(location.pathname)
                            ? 'active'
                            : ''
                        }`}
                      >
                        Bij- en nascholing
                      </Button>
                    )}
                    {(roleViewManager.isAdminView() ||
                      roleViewManager.isEducatorView()) &&
                      roleViewManager.hasPermission('LIST_USERS') && (
                        <Button
                          component={Link}
                          to="/gebruikers"
                          color="inherit"
                          className={`${classes.buttonLink} ${
                            /\/gebruikers.*/.test(location.pathname)
                              ? 'active'
                              : ''
                          }`}
                        >
                          Gebruikers
                        </Button>
                      )}
                    {(roleViewManager.isAdminView() ||
                      roleViewManager.isEducatorView()) &&
                      roleViewManager.hasPermission('LIST_FORMS') && (
                        <Button
                          component={Link}
                          to="/formulieren"
                          color="inherit"
                          className={`${classes.buttonLink} ${
                            /\/formulieren.*/.test(location.pathname)
                              ? 'active'
                              : ''
                          }`}
                        >
                          Formulieren
                        </Button>
                      )}
                  </Box>
                )}
                {account &&
                  (roleViewManager.isParticipantView() ||
                    roleViewManager.isEducatorView()) && (
                    <Button
                      component={Link}
                      to="/agenda"
                      color="inherit"
                      className={`${classes.buttonLink} ${
                        /\/agenda.*/.test(location.pathname) ? 'active' : ''
                      }`}
                    >
                      Agenda
                    </Button>
                  )}
                {account && roleViewManager.isEducatorView() && (
                  <Button
                    component={Link}
                    to="/beoordelingen"
                    color="inherit"
                    className={`${classes.buttonLink} ${
                      /\/beoordelingen.*/.test(location.pathname)
                        ? 'active'
                        : ''
                    }`}
                  >
                    Beoordelingen
                  </Button>
                )}
                {account &&
                  roleViewManager.isEducatorView() &&
                  roleViewManager.hasRole([
                    'ROLE_INSTITUTE_EDUCATOR',
                    'ROLE_PRACTICAL_TRAINER',
                  ]) && (
                    <Button
                      component={Link}
                      to="/mijn-deelnemers"
                      color="inherit"
                      className={`${classes.buttonLink} ${
                        /\/mijn-deelnemers.*/.test(location.pathname)
                          ? 'active'
                          : ''
                      }`}
                    >
                      Mijn deelnemers
                    </Button>
                  )}
                {(roleViewManager.isEducatorView() ||
                  roleViewManager.isAdminView()) &&
                  roleViewManager.hasPermission('VIEW_MODULES') && (
                    <Button
                      component={Link}
                      to="/modulen"
                      color="inherit"
                      className={`${classes.buttonLink} ${
                        /\/modulen.*/.test(location.pathname) ? 'active' : ''
                      }`}
                    >
                      Modulen
                    </Button>
                  )}
                {account && roleViewManager.isAdminView() && (
                  <Button
                    component={Link}
                    to="/api-keys"
                    color="inherit"
                    className={`${classes.buttonLink} ${
                      /\/api-keys.*/.test(location.pathname) ? 'active' : ''
                    }`}
                  >
                    API Keys
                  </Button>
                )}
                {account && roleViewManager.isAdminView() && (
                  <Button
                    component={Link}
                    to="/afas/synchronisatie-issues"
                    color="inherit"
                    className={`${classes.buttonLink} ${
                      /\/afas\/synchronisatie-issues.*/.test(location.pathname)
                        ? 'active'
                        : ''
                    }`}
                  >
                    Sync Issues
                  </Button>
                )}
                {account &&
                  (roleViewManager.isParticipantView() ||
                    roleViewManager.isELearningView()) && (
                    <Button
                      component={Link}
                      to="/e-learnings/moodle"
                      color="inherit"
                      className={classes.buttonLink}
                      target="_blank"
                    >
                      Gratis E-Learnings
                    </Button>
                  )}
              </Box>
              <Box className={classes.toolbarRight}>
                <Grid
                  container
                  spacing={2}
                  alignContent="center"
                  className={classes.toolbarRightGrid}
                >
                  {isLoggedIn && account && !roleViewManager.isELearningView() && (
                    <>
                      <Grid item>
                        <InfoBell />
                      </Grid>
                      <Grid item>
                        <NotificationBell />
                      </Grid>
                      <Grid item>
                        <MyContributionRequestsIcon />
                      </Grid>
                    </>
                  )}
                  {isLoggedIn && account && (
                    <Grid item>
                      <Button onClick={handleProfileMenuOpen} color="inherit">
                        <Avatar user={account} imitating={isImitating} />
                        <div className={classes.userName}>
                          <div>{account.fullName}</div>
                          {roleViewManager.getViewLabel()}
                        </div>
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          </Toolbar>
          <div className={classes.skewedBackground} />
        </Container>
      </AppBar>
      {renderMenu}
    </div>
  );
}

export default Navigation;
