import React, { useMemo, useState } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { EPAAssignment } from '../../types';
import AssignmentRepository from '../education/assignment/repository/AssignmentRepository';

interface EPALevelSelectorProps {
  assignment: EPAAssignment;
  disabled?: boolean;
}

const EPALevelSelector = (props: EPALevelSelectorProps) => {
  const notifications = useSnackbar();

  const disabled = props.disabled === true;

  const options = [1, 2, 3, 4, 5];
  const availableOptions = useMemo(() => {
    if (
      props.assignment.module &&
      'minLevel' in props.assignment.module &&
      'maxLevel' in props.assignment.module
    ) {
      const { module } = props.assignment;
      return options.filter(
        (option) => option >= 1 && option <= module.maxLevel,
      );
    }
    return [];
  }, [props.assignment, options]);

  const [epaLevel, setEpaLevel] = useState<number>(
    props.assignment.assignedLevel,
  );

  const handleLevelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number.parseInt(event.target.value, 10);

    new AssignmentRepository()
      .updateEPALevel(props.assignment, value)
      .then(() => {
        setEpaLevel(value);
        notifications.enqueueSnackbar('Niveau succesvol aangepast', {
          variant: 'success',
        });
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er is iets mis gegaan bij het aanpassen van het niveau',
          {
            variant: 'error',
          },
        );
      });
  };

  const title = `Toe te kennen niveau door beoordelaar (niveau ${props.assignment.requestedLevel} aangevraagd)`;

  return (
    <Box pt={2}>
      <FormControl component="fieldset">
        <FormLabel component="legend">{title}</FormLabel>
        <RadioGroup
          aria-label="level"
          name="level"
          value={epaLevel}
          onChange={handleLevelChange}
          row
        >
          {options.map((value) => (
            <FormControlLabel
              value={value}
              control={<Radio />}
              label={value}
              disabled={disabled || !availableOptions.includes(value)}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default EPALevelSelector;
