import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { Box, Button, Container, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

// eslint-disable-next-line import/no-unresolved
import { Flow } from 'flowjs';
import { AxiosResponse } from 'axios';
import Form, { FormContextFilesState } from '../../yard-forms/Form';
import { FieldState } from '../../yard-forms/fields/FieldProps';
import AssignmentRepository from './repository/AssignmentRepository';
import {
  Assignment,
  CompleteUser,
  Education,
  FormDataViolation,
} from '../../../types';
import AssignmentDialog from './AssignmentDialog';
import { FileUploadFormEntryField, FormEntry } from '../../yard-forms/types';
import AssignmentDelivery, {
  AssignmentEducatorOption,
} from './AssignmentDelivery';
import { ReactComponent as InReviewIllustration } from '../../../assets/images/in_review_illustration.svg';
import TextMedia from '../../../components/pageheader/TextMedia';
import { ReactComponent as FormIllustration } from '../../../assets/images/form_illustration.svg';
import Loader from '../../../components/Loader';
import settings from '../../../config/settings';
import AppContext from '../../../AppContext';
import EducationContext from '../EducationContext';
import EducationRepository from '../EducationRepository';
import {
  getFormOfAssignment,
  getModuleFromAssignment,
  getMostRecentEntry,
  handleFlowFileUploadForAssignmentForm,
  UploadingFileInterface,
} from './helpers';
import FlowProgress from '../../../components/file/FlowProgress';
import { createFlow } from '../../../utils/common';
import EPALevelSelector from '../../evaluation/EPALevelSelector';
import { DialogMessage } from './AssignmentDialogMessage';
import AssignmentMessageRepository from './repository/AssignmentMessageRepository';
import AssignmentSubmitMessageWarningDialog from './AssignmentSubmitMessageWarningDialog';
import AssignmentCollaborationButton from './AssignmentCollaborationButton';
import FormEntryContext from './FormEntryContext';

interface AssignmentFormProps {
  id: string;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: theme.spacing(6),
  },
  textMedia: {
    paddingBottom: 0,
  },
  wrapper: {
    maxWidth: 900,
    width: '100%',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  emailList: {
    display: 'inline-block',
  },
  emailListDescription: {
    maxWidth: 450,
  },
  inReview: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: `${settings.header.height + theme.spacing(10)}px`,
    width: '100%',
    height: '100%',
  },
  inReviewIllustration: {
    height: 215,
    width: 'auto',
  },
  inReviewDescription: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
    maxWidth: 450,
  },
  savingLoader: {
    position: 'fixed',
    top: `${settings.header.height + theme.spacing(5)}px`,
    right: theme.spacing(5),
  },
}));

const AssignmentForm = (props: AssignmentFormProps) => {
  const { id, className } = props;
  const history = useHistory();
  const classes = useStyles();
  const notifications = useSnackbar();
  const account = useSelector(
    (selector: {
      user: {
        account: CompleteUser;
      };
    }) => selector.user.account,
  );
  const { setAppState, roleViewManager } = useContext(AppContext);

  const urlParams = new URLSearchParams(window.location.search);
  const externalToken = urlParams.get('token');
  const repository = useMemo(
    () => new AssignmentRepository(externalToken),
    [externalToken],
  );

  const externalUser = externalToken !== null;

  const [flow] = useState<Flow>(createFlow());
  const [assignment, setAssignment] = useState<Assignment | null>(null);
  const [education, setEducation] = useState<Education | null>(null);
  const [violations, setViolations] = useState<FormDataViolation[]>([]);
  const [delivery, setDelivery] = useState<AssignmentEducatorOption[]>([]);
  const [hasEducatorOptions, setHasEducatorOptions] = useState<boolean>(false);
  const [deliveryError, setDeliveryError] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [mostRecentEntry, setMostRecentEntry] = useState<FormEntry | undefined>(
    undefined,
  );
  const [messageState, setMessageState] = useState<{
    message: string;
    dirty: boolean;
    warningDialogOpen: boolean;
    storedArguments: {
      formData: { [key: string]: FieldState };
      submit: boolean;
      dirty: boolean;
      files?: FormContextFilesState;
      notify?: boolean;
      onSuccess?: () => void;
      onFail?: () => void;
      autoSave: boolean;
      formEntry?: FormEntry;
    } | null;
  }>({
    message: '',
    dirty: false,
    warningDialogOpen: false,
    storedArguments: null,
  });
  const assignmentRef = useRef<Assignment | null>(assignment);
  const mostRecentEntryRef = useRef<FormEntry | undefined>(mostRecentEntry);
  const formEntryContextValue = useMemo(
    () => ({
      formEntry: mostRecentEntry,
      setFormEntry: setMostRecentEntry,
    }),
    [mostRecentEntry, setMostRecentEntry],
  );
  const module = assignment && getModuleFromAssignment(assignment);

  const getAssignment = useCallback(() => {
    repository
      .find(id)
      .then((response) => {
        setAssignment(response.data);
      })
      .catch((err) => {
        if (setAppState) {
          setAppState((prev) => ({
            ...prev,
            errorStatusCode: err.response.status,
          }));
        }
      });
  }, [id, repository, setAppState]);

  useEffect(() => {
    if (!assignment) {
      return;
    }

    const entry = getMostRecentEntry(assignment) || undefined;
    setMostRecentEntry(entry ? { ...entry } : undefined);
    mostRecentEntryRef.current = entry;
  }, [assignment]);

  const educationContextValue = useMemo(
    () => ({
      education,
      educationPermissions: {
        view: true,
        edit: assignment?.user.id === roleViewManager.getUser().id,
      },
    }),
    [education],
  );

  useEffect(() => {
    document.body.classList.add('footer-no-margin');

    return () => document.body.classList.remove('footer-no-margin');
  }, []);

  useEffect(getAssignment, [getAssignment]);

  const fetchEducation = () => {
    if (!assignment) {
      return;
    }

    const { educationId } = assignment;

    if (educationId) {
      new EducationRepository()
        .find(educationId)
        .then((response) => {
          setEducation(response.data.education);
        })
        .catch(() => {});
    }
  };

  useEffect(fetchEducation, [account]);
  useEffect(() => {
    if (education !== null) {
      return;
    }

    fetchEducation();
  }, [assignment]);

  const form = useMemo(() => {
    if (assignment) {
      return getFormOfAssignment(assignment);
    }

    return null;
  }, [assignment]);

  const redirectToModule = (assignment: Assignment) => {
    if (
      !account ||
      assignment.user.id !== account.id ||
      (!assignment.module && !assignment.assignmentContainer) ||
      assignment.educationId === undefined
    ) {
      return;
    }

    const moduleType = assignment.assignmentContainer
      ? assignment.assignmentContainer.module?.moduleType
      : assignment.module?.moduleType;

    if (moduleType === 'request') {
      history.push(
        `/onderwijs/${assignment.educationId}#tab=portfolio&portfolio=aanvragen`,
      );
    } else if (moduleType === 'epa') {
      history.push(
        `/onderwijs/${assignment.educationId}#tab=portfolio&portfolio=kba-epa`,
      );
    } else if (moduleType === 'guidance') {
      history.push(
        `/onderwijs/${assignment.educationId}#tab=portfolio&portfolio=voortgang-begeleiding`,
      );
    } else if (moduleType === 'practical') {
      history.push(`/onderwijs/${assignment.educationId}#tab=praktijk`);
    } else if (moduleType === 'education') {
      history.push(`/onderwijs/${assignment.educationId}#tab=education`);
    }
  };

  const doCreateOrUpdateEntry = useCallback(
    (
      formData: { [key: string]: FieldState },
      submit: boolean,
      dirty: boolean,
      files?: FormContextFilesState,
      notify?: boolean,
      onSuccess?: () => void,
      onFail?: () => void,
      autoSave: boolean = false,
      formEntry?: FormEntry,
    ) => {
      if (!assignment || saving) {
        return;
      }

      const hasEnteredData = Object.keys(formData).length >= 1;
      const isSubmitting =
        submit && (delivery.length >= 1 || !hasEducatorOptions);

      // prevent saving if there's nothing to save
      if (!hasEnteredData && !isSubmitting) {
        return;
      }

      setSaving(true);

      repository
        .createOrUpdateEntry(
          assignment,
          formData,
          delivery.map((e) => e.id),
          autoSave,
          submit,
          formEntry,
          notify,
        )
        .then(() => {
          // Fetch a newly created entry when no entry existed.
          if (autoSave && !mostRecentEntryRef.current) {
            getAssignment();
          }

          if (!autoSave) {
            getAssignment();

            if (externalUser) {
              // refresh the page
              history.go(0);
            } else {
              redirectToModule(assignment);
            }
          }

          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data.detail === 'validation_failed'
          ) {
            setViolations(err.response.data.violations);
          }

          if (onFail) {
            onFail();
          }
        })
        .finally(() => {
          setSaving(false);
        });
    },
    [assignment, saving, mostRecentEntry, delivery],
  );

  const handleCreateOrUpdateEntry = (
    formData: { [key: string]: FieldState },
    submit: boolean,
    dirty: boolean,
    files?: FormContextFilesState,
    notify?: boolean,
    onSuccess?: () => void,
    onFail?: () => void,
    autoSave: boolean = false,
  ) => {
    if (!assignment || !assignmentRef.current) {
      return;
    }

    if (dirty) {
      setMessageState({
        ...messageState,
        warningDialogOpen: true,
        storedArguments: {
          formData,
          submit,
          dirty,
          files,
          notify,
          onSuccess,
          onFail,
          autoSave,
        },
      });
      return;
    }

    // Only use most recent entry if it's still in draft.
    const formEntry =
      mostRecentEntryRef.current &&
      mostRecentEntryRef.current.status === 'draft'
        ? mostRecentEntryRef.current
        : undefined;

    const newAssignment: Assignment = { ...assignmentRef.current!! };

    const entryIndex = newAssignment.entries.findIndex(
      (e) => e.id === mostRecentEntryRef.current!!.id,
    );

    if (files && Object.entries(files).length > 0) {
      handleFlowFileUploadForAssignmentForm(
        autoSave ? createFlow() : flow,
        formData,
        files,
        (uploadingFiles: UploadingFileInterface[]) => {
          const newAssignment: Assignment = { ...assignmentRef.current!! };

          let shouldUpdate = false;

          uploadingFiles.forEach((uploadingFile) => {
            let entryField = newAssignment.entries[entryIndex]?.fields?.find(
              (field) => field.field.id === uploadingFile.fieldId,
            ) as FileUploadFormEntryField | undefined;

            // No entry exists.
            if (!newAssignment.entries[entryIndex]) {
              return;
            }

            if (!entryField) {
              entryField = {
                id: '',
                field: form.fields.find(
                  (f: any) => f.id === uploadingFile.fieldId,
                ),
                options: [],
                value: '',
                files: [],
              };

              newAssignment.entries[entryIndex].fields.push(entryField);
            }

            // Remove files that have been deleted.
            const existingFields = (
              formData[entryField.field.id].value as string
            ).split(',');

            entryField.files = entryField.files.filter((f) =>
              existingFields.includes(f.id),
            );

            // Should update upon removal.
            if (entryField.files.length !== existingFields.length) {
              shouldUpdate = true;
            }

            // Add newly created files.
            if (uploadingFile.fileEntity) {
              entryField.files.push(uploadingFile.fileEntity);
              // Should update when new file is added.
              shouldUpdate = true;
            }
          });

          if (shouldUpdate) {
            assignmentRef.current = newAssignment;
            setAssignment(newAssignment);
          }

          doCreateOrUpdateEntry(
            formData,
            submit,
            dirty,
            files,
            notify,
            onSuccess,
            onFail,
            autoSave,
            formEntry,
          );
        },
      );
    } else {
      doCreateOrUpdateEntry(
        formData,
        submit,
        dirty,
        files,
        notify,
        onSuccess,
        onFail,
        autoSave,
        formEntry,
      );
    }
  };

  const handleSubmit = (
    formData: { [key: string]: FieldState },
    files?: FormContextFilesState,
  ) => {
    if (hasEducatorOptions && delivery.length === 0) {
      setDeliveryError(true);
      return;
    }

    handleCreateOrUpdateEntry(
      formData,
      true,
      messageState.dirty,
      files,
      true,
      () => {
        notifications.enqueueSnackbar('De opdracht is ingeleverd!', {
          variant: 'success',
        });
      },
      () => {
        notifications.enqueueSnackbar('Fout bij inleveren van opdracht!', {
          variant: 'error',
        });
      },
    );
  };

  const handleSave = (
    formData: { [key: string]: FieldState },
    files?: FormContextFilesState,
    notify?: boolean,
    autoSave?: boolean,
  ) => {
    handleCreateOrUpdateEntry(
      formData,
      false,
      messageState.dirty,
      files,
      Boolean(notify),
      () => {
        if (!autoSave) {
          notifications.enqueueSnackbar('De opdracht is opgeslagen!', {
            variant: 'success',
          });
        }
      },
      () => {
        if (!autoSave) {
          notifications.enqueueSnackbar('Fout bij opslaan van opdracht!', {
            variant: 'error',
          });
        }
      },
      autoSave,
    );
  };

  const handleEducatorOptionsChange = (hasEducatorOptions: boolean) => {
    setHasEducatorOptions(hasEducatorOptions);
  };

  const handleDeliveryChange = (educators: AssignmentEducatorOption[]) => {
    if (deliveryError) {
      setDeliveryError(educators.length === 0);
    }
    setDelivery(educators);
  };

  const createNewMessage = async (
    content: string,
  ): Promise<AxiosResponse<DialogMessage>> =>
    new AssignmentMessageRepository().create(assignment!!.id, content);

  const handleNewMessage = (message: string) => {
    if (!assignment) {
      return;
    }

    createNewMessage(message)
      .then((response) => {
        setAssignment({
          ...assignment,
          messages: [...assignment.messages, response.data],
        });
      })
      .finally(() => {
        setMessageState({ ...messageState, dirty: false });
      });
  };

  const handleChangeMessage = (message: string) => {
    setMessageState({ ...messageState, message, dirty: true });
  };

  const handleDeleteMessage = (message: DialogMessage) => {
    if (!assignment) {
      return;
    }

    const newMessages = [...assignment.messages];
    newMessages.splice(
      newMessages.findIndex((m) => m.id === message.id),
      1,
    );

    new AssignmentMessageRepository()
      .delete(assignment.id, message.id)
      .then(() => {
        setAssignment({ ...assignment, messages: newMessages });
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Fout bij het verwijderen van het bericht.',
          { variant: 'error' },
        );
      });
  };

  const handleEditMessage = (message: DialogMessage, newContent: string) => {
    if (!assignment) {
      return;
    }

    const newMessages = [...assignment.messages];
    const messageIndex = newMessages.findIndex((m) => m.id === message.id);

    if (messageIndex) {
      newMessages[messageIndex].content = newContent;
    }

    new AssignmentMessageRepository()
      .update(assignment.id, message.id, newContent)
      .then(() => {
        setAssignment({ ...assignment, messages: newMessages });
      });
  };

  const handleSubmitWithoutMessage = () => {
    const { storedArguments } = messageState;

    if (!storedArguments) {
      return;
    }

    setMessageState({
      ...messageState,
      dirty: false,
      warningDialogOpen: false,
      storedArguments: null,
    });

    handleCreateOrUpdateEntry(
      storedArguments.formData,
      storedArguments.submit,
      false,
      storedArguments.files,
      storedArguments.notify,
      storedArguments.onSuccess,
      storedArguments.onFail,
      storedArguments.autoSave,
    );
  };

  const handleSubmitWithMessage = () => {
    const { storedArguments } = messageState;

    if (!storedArguments) {
      return;
    }

    setMessageState({
      ...messageState,
      dirty: false,
      warningDialogOpen: false,
      storedArguments: null,
    });

    createNewMessage(messageState.message).then(() => {
      handleCreateOrUpdateEntry(
        storedArguments.formData,
        storedArguments.submit,
        false,
        storedArguments.files,
        storedArguments.notify,
        storedArguments.onSuccess,
        storedArguments.onFail,
        storedArguments.autoSave,
      );
    });
  };

  useEffect(() => {
    if (assignment) {
      assignmentRef.current = assignment;
    }
  }, [assignment]);

  // if we're accessing this form as an educator,
  // the form must be read-only until the assignment has been handed in by the participant
  const viewingAsEducator = roleViewManager.isEducatorView() && !externalUser;
  const handedIn =
    assignment !== null && (assignment.inReview || assignment.finished);
  const readOnly = viewingAsEducator ? !handedIn : false;

  if (!assignment) {
    return <Loader />;
  }

  // Block when assignment is not open for entries.
  if (!assignment.editable) {
    return (
      <div className={classes.inReview}>
        <div>
          <InReviewIllustration className={classes.inReviewIllustration} />
          <Typography variant="body1" className={classes.inReviewDescription}>
            De opdracht is ingeleverd ter beoordeling en kan niet gewijzigd
            worden.
          </Typography>
          {!externalUser && (
            <Typography variant="body1" className={classes.inReviewDescription}>
              Je ontvangt een notificatie zodra de opdracht beoordeeld is.
            </Typography>
          )}
          {!externalUser && (
            <Box display="flex" justifyContent="center" mt={3}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => redirectToModule(assignment)}
              >
                Terug naar onderwijs
              </Button>
            </Box>
          )}
        </div>
      </div>
    );
  }

  const description = (
    <>
      <Box mb={form.description ? 2 : 0}>
        {module && (
          <Box mb={1}>
            <strong>Module:</strong>
            <Box ml={1} display="inline-block">
              {module.publishedName}
            </Box>
          </Box>
        )}
        {roleViewManager.isAdminOrImitatingAdmin() && (
          <Box mb={1}>
            <strong>Formulier ID:</strong>
            <Box ml={1} display="inline-block">
              {form.id}
            </Box>
          </Box>
        )}
        {assignment.user.id !== roleViewManager.getUser().id && (
          <Box mb={1}>
            <strong>Deelnemer:</strong>
            <Box ml={1} display="inline-block">
              {assignment.user.fullNameLastNameFirst}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );

  const beforeSubmit = (
    <Box mt={2} mb={2}>
      {assignment && 'assignedLevel' in assignment && (
        <EPALevelSelector assignment={assignment} disabled />
      )}
      {!externalUser && (
        <>
          {assignment.form?.externalFields && (
            <AssignmentCollaborationButton
              assignment={assignment}
              variant="complete"
            />
          )}
          <AssignmentDialog
            assignment={assignment}
            onNewMessage={handleNewMessage}
            onDelete={handleDeleteMessage}
            onEdit={handleEditMessage}
            onChange={handleChangeMessage}
          />
          <AssignmentSubmitMessageWarningDialog
            open={messageState.warningDialogOpen}
            message={messageState.message}
            onSubmit={handleSubmitWithoutMessage}
            onSubmitWithMessage={handleSubmitWithMessage}
          />
          <Box mt={2}>
            <AssignmentDelivery
              po={
                account &&
                assignment.delivery &&
                assignment.delivery.io &&
                assignment.delivery.po
              }
              io={
                account &&
                assignment.delivery &&
                assignment.delivery.io &&
                assignment.delivery.po
              }
              teacher={account !== undefined}
              assignment={assignment}
              onEducatorOptionsChange={handleEducatorOptionsChange}
              onChange={handleDeliveryChange}
              error={deliveryError}
            />
          </Box>
        </>
      )}
      <FlowProgress flow={flow} />
    </Box>
  );

  return (
    <div className={`${className || ''} ${classes.root}`}>
      <div
        className={classes.savingLoader}
        style={{ display: saving ? 'block' : 'none' }}
      >
        <Loader inline />
      </div>
      <EducationContext.Provider value={educationContextValue}>
        <TextMedia
          name={assignment.name}
          illustration={<FormIllustration />}
          className={classes.textMedia}
          wrapperClassName={classes.wrapper}
          description={description}
        />
        <Container className={classes.container}>
          <div className={classes.wrapper}>
            <FormEntryContext.Provider value={formEntryContextValue}>
              {form && (
                <Form
                  title={false}
                  description
                  form={form}
                  entry={mostRecentEntry}
                  onSubmit={handleSubmit}
                  onSave={handleSave}
                  submitable={!readOnly && !saving}
                  violations={violations}
                  readOnly={readOnly}
                  beforeSubmit={beforeSubmit}
                  externalUser={externalUser}
                />
              )}
            </FormEntryContext.Provider>
          </div>
        </Container>
      </EducationContext.Provider>
    </div>
  );
};

export default AssignmentForm;
