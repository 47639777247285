import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { Flow as IFlow } from 'flowjs';
// @ts-ignore
import Flow from '@flowjs/flow.js';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { grey } from '@material-ui/core/colors';
import { useSnackbar } from 'notistack';

import { Alert } from '@material-ui/lab';
import { AxiosResponse } from 'axios';
import PageContainer from '../../components/PageContainer';
import EvaluationRepository, {
  EducationPointsSummary,
} from './EvaluationRepository';
import { FormEntry as FormEntryInterface } from '../yard-forms/types';
import { Assignment, EvaluationOption, FormDataViolation } from '../../types';
import AssignmentEvaluations from './AssignmentEvaluation';
import FormEntry from '../yard-forms/FormEntry';
import AssignmentDialog from '../education/assignment/AssignmentDialog';
import Form, { FormContextFilesState } from '../yard-forms/Form';
import { FieldState } from '../yard-forms/fields/FieldProps';
import AppContext from '../../AppContext';
import AssignmentRepository from '../education/assignment/repository/AssignmentRepository';
import {
  getFormOfAssignment,
  getModuleFromAssignment,
  handleFlowFileUploadForAssignmentForm,
} from '../education/assignment/helpers';
import FlowProgress from '../../components/file/FlowProgress';
import FormDescription from '../yard-forms/FormDescription';
import { DialogMessage } from '../education/assignment/AssignmentDialogMessage';
import AssignmentMessageRepository from '../education/assignment/repository/AssignmentMessageRepository';
import AssignmentSubmitMessageWarningDialog from '../education/assignment/AssignmentSubmitMessageWarningDialog';
import EPALevelSelector from './EPALevelSelector';

type EvaluationAction = 'evaluate' | 'reject' | 'save';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    maxWidth: 900,
    width: '100%',
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  title: {
    fontSize: 28,
    fontWeight: 700,
  },
  subTitle: {
    fontSize: 14,
  },
  fieldTitle: {
    fontWeight: 700,
    fontSize: 22,
  },
  entries: {
    marginTop: theme.spacing(2),
  },
  evaluation: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 250,
  },
  evaluationControl: {
    width: 250,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  margin: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  evaluationContainer: {
    padding: theme.spacing(2),
    background: grey[100],
  },
  dialog: {
    marginTop: theme.spacing(2),
  },
}));

const EvaluationView =
  /**
   *
   */
  () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const notifications = useSnackbar();
    const classes = useStyles();

    const repository = new EvaluationRepository();
    const { appState, setAppState, roleViewManager } = useContext(AppContext);
    const [assignment, setAssignment] = useState<Assignment>();
    const [pointsSummaries, setPointsSummaries] = useState<
      EducationPointsSummary[]
    >([]);
    const [files, setFiles] = useState<FormContextFilesState>({});
    const [options, setOptions] = useState<EvaluationOption[]>([]);
    const [valuation, setValuation] = useState<string>('');
    const [grade, setGrade] = useState<number | null>(null);
    const [formData, setFormData] = useState<{ [key: string]: FieldState }>({});
    const [isMostRecentEntry, setIsMostRecentEntry] = useState<boolean>(true);
    const [entry, setEntry] = useState<FormEntryInterface | null>(null);
    const [violations, setViolations] = useState<FormDataViolation[]>([]);
    const [saving, setSaving] = useState<boolean>(false);
    const [messageState, setMessageState] = useState<{
      message: string;
      dirty: boolean;
      warningDialogOpen: boolean;
      storedAction: EvaluationAction | null;
    }>({
      message: '',
      dirty: false,
      warningDialogOpen: false,
      storedAction: null,
    });

    const flow: IFlow = new Flow({
      target: `${process.env.REACT_APP_API_URL}/api/file`,
      withCredentials: true,
    });

    const loadAssignment = () => {
      repository
        .find(id)
        .then((response) => setAssignment(response.data))
        .catch((err) => {
          if (appState && setAppState) {
            setAppState({ ...appState, errorStatusCode: err.response.status });
          }
        });
    };

    useEffect(() => {
      loadAssignment();

      repository
        .getOptions()
        .then((response) => setOptions(response.data))
        .catch((err) => {
          if (appState && setAppState) {
            setAppState({ ...appState, errorStatusCode: err.response.status });
          }
        });
    }, [id]);

    useEffect(() => {
      if (!assignment) {
        return;
      }

      new EvaluationRepository()
        .pointsSummaries(assignment)
        .then((response) => {
          setPointsSummaries(response.data);
        })
        .catch(() => {});
    }, [assignment]);

    const handleValuationChange = (
      event: React.ChangeEvent<{ value: unknown }>,
    ) => {
      setValuation(event.target.value as string);
    };

    const handleGradeChange = (
      event: React.ChangeEvent<{ value: unknown }>,
    ) => {
      setGrade(parseFloat(event.target.value as string));
    };

    function handleSubmit(dirty?: boolean) {
      if (!assignment) {
        return;
      }

      if (dirty === undefined ? messageState.dirty : dirty) {
        setMessageState({
          ...messageState,
          warningDialogOpen: true,
          storedAction: 'evaluate',
        });

        return;
      }

      setSaving(true);

      const doSubmit = () => {
        repository
          .evaluate(assignment, valuation, grade, formData)
          .then(() => {
            history.push('/beoordelingen');
            notifications.enqueueSnackbar(
              'De opdracht is succesvol beoordeeld!',
              {
                variant: 'success',
              },
            );
          })
          .catch((err) => {
            if (err.response.data.detail === 'validation_failed') {
              setViolations(err.response.data.violations);
            }

            notifications.enqueueSnackbar(
              'Er is iets fout gegaan bij het beoordelen van de opdracht!',
              { variant: 'error' },
            );
          })
          .finally(() => setSaving(false));
      };

      if (files) {
        handleFlowFileUploadForAssignmentForm(flow, formData, files, doSubmit);
      } else {
        doSubmit();
      }
    }

    const handleReject = (dirty?: boolean) => {
      if (!assignment) {
        return;
      }

      if (dirty === undefined ? messageState.dirty : dirty) {
        setMessageState({
          ...messageState,
          warningDialogOpen: true,
          storedAction: 'reject',
        });

        return;
      }

      setSaving(true);

      const doReject = () => {
        repository
          .reject(assignment, formData)
          .then(() => {
            history.push('/beoordelingen');
            notifications.enqueueSnackbar(
              'Opdracht is teruggegeven aan de deelnemer.',
              {
                variant: 'success',
              },
            );
          })
          .catch((err) => {
            if (err.response.data.detail === 'validation_failed') {
              setViolations(err.response.data.violations);
            }

            notifications.enqueueSnackbar(
              'Er is iets fout gegaan bij het teruggeven van de opdracht!',
              {
                variant: 'error',
              },
            );
          })
          .finally(() => setSaving(false));
      };

      if (files) {
        handleFlowFileUploadForAssignmentForm(flow, formData, files, doReject);
      } else {
        doReject();
      }
    };

    const handleSave = (dirty?: boolean) => {
      if (
        !assignment ||
        !entry ||
        !isMostRecentEntry ||
        Object.keys(formData).length === 0
      ) {
        return;
      }

      if (dirty === undefined ? messageState.dirty : dirty) {
        setMessageState({
          ...messageState,
          warningDialogOpen: true,
          storedAction: 'save',
        });

        return;
      }

      setSaving(true);

      const doSave = () => {
        new AssignmentRepository()
          .createOrUpdateEntry(
            assignment,
            formData,
            [],
            false,
            false,
            entry,
            false,
          )
          .then(() => {
            notifications.enqueueSnackbar(
              'De beoordeling is succesvol opgeslagen.',
              { variant: 'success' },
            );
          })
          .catch((err) => {
            if (err.response.data.detail === 'validation_failed') {
              setViolations(err.response.data.violations);
            }
            notifications.enqueueSnackbar(
              'Er is iets fout gegaan bij het opslaan van de beoordeling.',
              { variant: 'error' },
            );
          })
          .finally(() => setSaving(false));
      };

      if (files) {
        handleFlowFileUploadForAssignmentForm(flow, formData, files, doSave);
      } else {
        doSave();
      }
    };

    const form = useMemo(() => {
      if (assignment) {
        return getFormOfAssignment(assignment);
      }

      return null;
    }, [assignment]);

    const handleFormDataChange = (
      formData: { [key: string]: FieldState },
      files: FormContextFilesState,
    ) => {
      setFormData(formData);
      setFiles(files);
    };

    const handleFormEntryChange = (
      entry: FormEntryInterface,
      mostRecentEntry: boolean,
    ) => {
      setEntry(entry);
      setIsMostRecentEntry(mostRecentEntry);
    };

    const createNewMessage = async (
      content: string,
    ): Promise<AxiosResponse<DialogMessage>> =>
      new AssignmentMessageRepository().create(assignment!!.id, content);

    const handleNewMessage = (message: string) => {
      if (!assignment) {
        return;
      }

      createNewMessage(message)
        .then((response) => {
          setAssignment({
            ...assignment,
            messages: [...assignment.messages, response.data],
          });
        })
        .finally(() => {
          setMessageState({ ...messageState, dirty: false });
        });
    };

    const handleChangeMessage = (message: string) => {
      setMessageState({ ...messageState, message, dirty: true });
    };

    const handleDeleteMessage = (message: DialogMessage) => {
      if (!assignment) {
        return;
      }

      const newMessages = [...assignment.messages];
      newMessages.splice(
        newMessages.findIndex((m) => m.id === message.id),
        1,
      );

      new AssignmentMessageRepository()
        .delete(assignment.id, message.id)
        .then(() => {
          setAssignment({ ...assignment, messages: newMessages });
        })
        .catch(() => {
          notifications.enqueueSnackbar(
            'Fout bij het verwijderen van het bericht.',
            { variant: 'error' },
          );
        });
    };

    const handleEditMessage = (message: DialogMessage, newContent: string) => {
      if (!assignment) {
        return;
      }

      const newMessages = [...assignment.messages];
      const messageIndex = newMessages.findIndex((m) => m.id === message.id);

      if (!messageIndex) {
        return;
      }

      const oldContent = newMessages[messageIndex].content;
      newMessages[messageIndex].content = newContent;

      new AssignmentMessageRepository()
        .update(assignment.id, message.id, newContent)
        .then(() => {
          setAssignment({ ...assignment, messages: newMessages });
        })
        .catch(() => {
          notifications.enqueueSnackbar(
            'Fout bij het bewerken van het bericht',
            { variant: 'error' },
          );

          // Revert message.
          newMessages[messageIndex].content = oldContent;
          setAssignment({ ...assignment, messages: newMessages });
        });
    };

    const proceedSubmission = () => {
      switch (messageState.storedAction) {
        case 'evaluate':
          return handleSubmit(false);
        case 'reject':
          return handleReject(false);
        case 'save':
          return handleSave(false);
        default:
          return null;
      }
    };

    const handleSubmitWithoutMessage = () => {
      const { storedAction } = messageState;

      if (!storedAction) {
        return;
      }

      setMessageState({
        ...messageState,
        dirty: false,
        warningDialogOpen: false,
      });

      proceedSubmission();
    };

    const handleSubmitWithMessage = () => {
      const { storedAction } = messageState;

      if (!storedAction || !assignment) {
        return;
      }

      setMessageState({
        ...messageState,
        dirty: false,
        warningDialogOpen: false,
      });

      createNewMessage(messageState.message).then((response) => {
        setAssignment({
          ...assignment,
          messages: [...assignment.messages, response.data],
        });
        proceedSubmission();
      });
    };

    const module = assignment && getModuleFromAssignment(assignment);

    const handedIn =
      assignment !== undefined && (assignment.inReview || assignment.finished);
    const readOnly = !handedIn;

    return (
      <PageContainer containerClassName={classes.wrapper}>
        {assignment && (
          <div className={classes.header}>
            <Typography variant="h3" component="h1" className={classes.title}>
              {assignment.name}
            </Typography>
            <Box mb={1}>
              <strong>Deelnemer:</strong>
              <Box ml={1} display="inline">
                {assignment.user.fullName}
              </Box>
            </Box>
            {module && (
              <Box mb={1}>
                <strong>Module:</strong>
                <Box ml={1} display="inline">
                  {module.publishedName}
                </Box>
              </Box>
            )}
            {roleViewManager.isAdminOrImitatingAdmin() && (
              <Box mb={1}>
                <strong>Formulier ID:</strong>
                <Box ml={1} display="inline">
                  {entry ? entry.form.id : form.id}
                </Box>
              </Box>
            )}
            {assignment.module?.moduleType === 'request' && pointsSummaries && (
              <Box mb={1}>
                {pointsSummaries.map((summary) => {
                  return (
                    <Typography variant="body1" className={classes.subTitle}>
                      {`${summary.label}:`}
                      <Box ml={1} display="inline-block">
                        {`${summary.remaining} punten nog in te schrijven`}
                      </Box>
                    </Typography>
                  );
                })}
              </Box>
            )}
          </div>
        )}
        {form && (
          <Box mb={2}>
            <FormDescription form={form} />
          </Box>
        )}
        {assignment && form && assignment.entries.length === 0 && (
          <Form
            form={form}
            onSubmit={() => {}}
            submitable={false}
            title={false}
            description={false}
            readOnly={readOnly}
            hideSubmit
          />
        )}
        {assignment && form && (
          <FormEntry
            form={form}
            entries={assignment.entries}
            onFormDataChange={handleFormDataChange}
            onFormEntryChange={handleFormEntryChange}
            readOnly={readOnly}
            violations={violations}
          />
        )}
        {assignment && 'assignedLevel' in assignment && (
          <EPALevelSelector
            assignment={assignment}
            disabled={!assignment.inReview && !assignment.finished}
          />
        )}
        {assignment && assignment.messages && (
          <>
            <AssignmentDialog
              assignment={assignment}
              onNewMessage={handleNewMessage}
              onEdit={handleEditMessage}
              onDelete={handleDeleteMessage}
              onChange={handleChangeMessage}
              className={classes.dialog}
            />
            <AssignmentSubmitMessageWarningDialog
              open={messageState.warningDialogOpen}
              message={messageState.message}
              onSubmit={handleSubmitWithoutMessage}
              onSubmitWithMessage={handleSubmitWithMessage}
            />
          </>
        )}
        {assignment && assignment.finished && form && (
          <AssignmentEvaluations assignment={assignment} />
        )}
        {violations.length > 0 && (
          <Box mb={2} mt={2}>
            <Alert severity="error">
              Een aantal velden zijn niet correct ingevuld.
            </Alert>
          </Box>
        )}
        {isMostRecentEntry && (assignment?.inReview || assignment?.finished) && (
          <>
            <Grid container spacing={2} className={classes.evaluation}>
              {options && assignment && form && form.valuation && (
                <Grid item md={12}>
                  <FormControl className={classes.evaluationControl}>
                    <InputLabel id="evaluation-select-label">
                      Waardering
                    </InputLabel>
                    <Select
                      labelId="evaluation-select-label"
                      id="evaluation-select"
                      value={valuation}
                      onChange={handleValuationChange}
                      autoWidth
                    >
                      <MenuItem value="">
                        <em>Geen waardering</em>
                      </MenuItem>
                      {options &&
                        form &&
                        options
                          .filter((option) =>
                            form.evaluationOptions.includes(option.id),
                          )
                          .map((option) => (
                            <MenuItem value={option.id}>
                              {option.label}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {assignment && form.grade && (
                <Grid item>
                  <TextField
                    id="grade"
                    label="Cijfer"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleGradeChange}
                    value={grade}
                    variant="outlined"
                    helperText="Getal tussen 0 en 10"
                  />
                </Grid>
              )}
            </Grid>
            <FlowProgress flow={flow} />
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<FontAwesomeIcon icon={['fal', 'check']} />}
                  onClick={() => handleSubmit()}
                  disabled={saving || !assignment || (!valuation && !grade)}
                >
                  Beoordeling opslaan
                </Button>
              </Grid>
              {!assignment?.finished && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<FontAwesomeIcon icon={['fal', 'times']} />}
                    onClick={() => handleReject()}
                    disabled={saving || !assignment}
                  >
                    Opnieuw indienen
                  </Button>
                </Grid>
              )}
              {!assignment?.finished && (
                <Grid item>
                  <Button
                    variant="outlined"
                    color="default"
                    startIcon={<FontAwesomeIcon icon={['fad', 'save']} />}
                    onClick={() => handleSave()}
                    disabled={saving || !assignment}
                  >
                    Opslaan voor latere beoordeling
                  </Button>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </PageContainer>
    );
  };

export default EvaluationView;
