import React, { useEffect, useMemo, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { ApiFilterCriteria } from '../../types';
import FilterPopover from './FilterPopover';
import { FilterColumnOption, FilterColumnOptionCallback } from './types';

interface CheckboxFilterProps {
  field: string;
  name: string;
  onChange: (options: FilterColumnOption[]) => void;
  criteria: ApiFilterCriteria;
  options?: FilterColumnOption[] | FilterColumnOptionCallback;
  showLabel?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  formGroup: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  optionsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: 450,
    width: 350,
    '& .MuiFormControlLabel-root': {
      width: '100%',
    },
  },
}));

const CheckboxFilter = (props: CheckboxFilterProps) => {
  const { field, name, onChange, criteria, showLabel } = props;
  const classes = useStyles();
  const [filters, setFilters] = useState<{ [key: string]: boolean }>({});
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [currentCriteria, setCurrentCriteria] =
    useState<ApiFilterCriteria | null>(null);

  const noItems: FilterColumnOption[] = useMemo(() => [], []);
  const noFilters: { [key: string]: boolean } = useMemo(() => ({}), []);

  const [options, setOptions] = useState<FilterColumnOption[]>(
    props.options && Array.isArray(props.options) ? props.options : noItems,
  );

  const fetchOptions = async () => {
    if (props.options && typeof props.options === 'function') {
      const options = await props.options();
      setOptions(options);
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    if (checked) {
      setFilters({ ...filters, [name]: true });
    } else {
      delete filters[name];
      setFilters({ ...filters });
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    if (!isInitialized) {
      return;
    }

    if (options.length === 0) {
      return;
    }

    onChange(options.filter((option) => filters[option.value] || false));
  }, [isInitialized, options, filters]);

  /**
   * Handle initial load.
   */
  useEffect(() => {
    if (options.length === 0) {
      return;
    }

    // If the criteria did not change, don't run update.
    if (
      criteria !== null &&
      JSON.stringify(criteria) === JSON.stringify(currentCriteria)
    ) {
      return;
    }

    if (criteria.filters) {
      const fieldFilter = criteria.filters[field];

      if (
        (typeof fieldFilter === 'string' || Array.isArray(fieldFilter)) &&
        fieldFilter.length > 0
      ) {
        const newFilters: { [key: string]: boolean } = {};

        if (Array.isArray(fieldFilter)) {
          const currentOptionValues = options.map((o) => o.value);
          fieldFilter.forEach(({ value }) => {
            if (currentOptionValues.includes(value)) {
              newFilters[value] = true;
            }
          });
        } else {
          fieldFilter.split(',').forEach((id) => {
            newFilters[id] = true;
          });
        }

        setFilters(newFilters);
      } else {
        setFilters(noFilters);
      }
    }

    setCurrentCriteria(criteria);
    setIsInitialized(true);
  }, [criteria, options]);

  const hasFilters = useMemo(() => {
    return Object.entries(filters).length > 0;
  }, [filters]);

  return (
    <FilterPopover name={name} showLabel={showLabel} hasFilters={hasFilters}>
      <FormGroup className={classes.formGroup}>
        <div className={classes.optionsContainer}>
          {options.map((option) => {
            const control = (
              <Checkbox
                checked={filters[option.value] || false}
                onChange={handleCheckboxChange}
                name={option.value}
              />
            );

            return (
              <FormControlLabel
                control={control}
                key={`key-${option.value}`}
                label={option.label}
              />
            );
          })}
        </div>
      </FormGroup>
    </FilterPopover>
  );
};

export default CheckboxFilter;
