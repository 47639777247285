import { AxiosResponse } from 'axios';

import {
  Assignment,
  AssignmentAccessToken,
  AssignmentContainer,
  EPAAssignment,
  FormDataValidationViolationResponse,
  MinimalUser,
} from '../../../../types';
import { FieldState } from '../../../yard-forms/fields/FieldProps';
import ApiClient from '../../../../api/ApiClient';
import { FormEntry } from '../../../yard-forms/types';

class AssignmentRepository {
  token: string | null;

  constructor(token: string | null = null) {
    this.token = token;
  }

  find(id: string): Promise<AxiosResponse<Assignment>> {
    return ApiClient.get(`/api/assignment/${id}`, this.getParams());
  }

  delete(id: string): Promise<AxiosResponse<[]>> {
    return ApiClient.delete(`/api/assignment/${id}`);
  }

  createOrUpdateEntry(
    assignment: Assignment,
    formData: { [key: string]: FieldState },
    assignedUsers: string[],
    autoSave: boolean = false,
    submit: boolean = false,
    formEntry?: FormEntry,
    notify?: boolean,
  ): Promise<AxiosResponse<Assignment> | FormDataValidationViolationResponse> {
    const endpoint =
      formEntry === undefined
        ? `/api/assignment/${assignment.id}/entries`
        : `/api/assignment/${assignment.id}/entries/${formEntry.id}`;

    const token = new URLSearchParams(window.location.search).get('token');

    return ApiClient.post(
      endpoint,
      {
        formData,
        assignedUsers,
        autoSave,
        submit,
        notify,
        token,
      },
      {
        params: this.getParams(),
      },
    );
  }

  createFromContainer(container: AssignmentContainer) {
    return ApiClient.get(`/api/assignment-container/${container.id}/create`);
  }

  getAccessTokens(id: string): Promise<AxiosResponse<AssignmentAccessToken[]>> {
    return ApiClient.get(`/api/assignment/${id}/tokens`);
  }

  createAccessToken(
    assignment: Assignment,
    emails: string[],
    explanation: string | null,
  ) {
    return ApiClient.post(`/api/assignment/${assignment.id}/external-invites`, {
      emails,
      explanation,
    });
  }

  updateEPALevel(assignment: EPAAssignment, level: number) {
    return ApiClient.put(`/api/assignment/${assignment.id}/epa-level`, {
      level,
    });
  }

  updateEvaluators(assignment: Assignment, evaluators: MinimalUser[]) {
    return ApiClient.post(
      `/api/assignment/${assignment.id}/evaluators`,
      evaluators,
    );
  }

  private getParams(params: { [key: string]: any } = {}) {
    const result: { [key: string]: any } = { ...params };

    if (this.token !== null) {
      result.token = this.token;
    }

    return result;
  }
}

export default AssignmentRepository;
