// eslint-disable-next-line import/no-unresolved
import { Flow } from 'flowjs';
import {
  Assignment,
  AssignmentAccessToken,
  File as FileInterface,
} from '../../../types';
import { FormEntry } from '../../yard-forms/types';
import { FieldState } from '../../yard-forms/fields/FieldProps';
import { FormContextFilesState } from '../../yard-forms/Form';

/**
 * Get the most recent entry of an assignment.
 * @param assignment
 */
export const getMostRecentEntry = (
  assignment: Assignment,
): FormEntry | null => {
  if (assignment.entries.length === 0) {
    return null;
  }

  return assignment.entries.sort((a, b) =>
    new Date(a.created) > new Date(b.created) ? -1 : 1,
  )[0];
};

/**
 * Sort assignments by name, taking numbers into account.
 *
 * Cases:
 * - `5 abc` before `10 abc` (numerical comparison)
 * - `abc 123 def 5` before `abc 123 def 10` (numerical comparison if start of the string is the same)
 * - `abc 1.2.3` before `abc 1.2.10` (numerical comparison with period notation)
 */
export const sortAssignments = (assignments: Assignment[]): Assignment[] =>
  assignments.sort((a, b) => {
    // split strings into parts where multiple whitespace characters are seen as one
    const aParts = a.name.split(/[\s]+/);
    const bParts = b.name.split(/[\s]+/);

    for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
      let aPart = aParts[i];
      let bPart = bParts[i];

      while (aPart !== bPart) {
        const aDigitMatches = aPart.match(/^(\d+)/);
        const bDigitMatches = bPart.match(/^(\d+)/);

        if (!aDigitMatches || !bDigitMatches) {
          // case where one (or both) of the parts doesn't start with a digit
          return aPart.localeCompare(bPart);
        }

        // case where both parts start with a digit
        const aDigit = parseInt(aDigitMatches[0], 10);
        const bDigit = parseInt(bDigitMatches[0], 10);

        if (aDigit !== bDigit) {
          // case where digits differ
          return aDigit < bDigit ? -1 : 1;
        }

        // shorten the parts and retry, in case we're comparing e.g. `2.10.1` with `2.5.3`
        const numCharacters = `${aDigit}`.length;
        aPart = aPart.substr(numCharacters, aPart.length - numCharacters);
        bPart = bPart.substr(numCharacters, bPart.length - numCharacters);

        if (aPart[0] === '.' && bPart[0] === '.') {
          // in the above example, we're now comparing `10.1` and `5.3` in the next iteration of the loop
          aPart = aPart.substr(1, aPart.length - 1);
          bPart = bPart.substr(1, bPart.length - 1);
        }
      }
    }

    if (aParts.length !== bParts.length) {
      return aParts.length < bParts.length ? -1 : 1;
    }

    // all parts are the same,
    // and we already checked before that one string is not a substring of the other
    // so the strings must be the same
    return 0;
  });

/**
 * TODO: Refactor after https://github.com/yardinternet/mijn-nspoh/pull/80.
 */
export const getModuleFromAssignment = (assignment: Assignment) => {
  if (assignment.assignmentContainer) {
    return assignment.assignmentContainer.module;
  }

  return assignment.module;
};

export interface UploadingFileInterface {
  file: File;
  fieldId: string;
  fileEntity?: FileInterface;
}

/**
 * Handle the upload of files with Flow and update the form data with the retrieved file id's.
 *
 * @param flow
 * @param formData
 * @param files
 * @param onComplete
 */
export const handleFlowFileUploadForAssignmentForm = (
  flow: Flow,
  formData: { [key: string]: FieldState },
  files: FormContextFilesState,
  onComplete?: (uploadingFiles: UploadingFileInterface[]) => void,
) => {
  // Keeps track of which file belongs to which form field.
  const uploadingFiles: UploadingFileInterface[] = [];

  /**
   * Triggers when a file upload has succeeded.
   * Proceeds to add the new file ID to the associated field of the file.
   */
  flow.on('fileSuccess', (flowFile, message) => {
    const response: FileInterface = JSON.parse(message);
    const uploadingFile = uploadingFiles.find(
      ({ file }) => flowFile.file === file,
    );

    if (!uploadingFile) {
      return;
    }

    // Obtain the current files in the field, if any exist.
    const fieldValue: string =
      (formData[uploadingFile.fieldId].value as string | null) || '';

    const filesIndex = files[uploadingFile.fieldId].findIndex(
      (file) => file.file === uploadingFile.file,
    );

    // eslint-disable-next-line no-param-reassign
    files[uploadingFile.fieldId][filesIndex] = {
      uploaded: true,
      file: uploadingFile.file,
    };

    uploadingFile.fileEntity = response;

    // eslint-disable-next-line no-param-reassign
    formData[uploadingFile.fieldId].value = [
      response.id,
      ...fieldValue.split(','),
    ].join(',');
  });

  // Callback when the entire upload has finished.
  if (onComplete) {
    flow.on('complete', () => {
      onComplete(uploadingFiles);

      // Remove event listeners on Flow instance.
      flow.off();
    });
  }

  // Build the uploadingFiles array, and add all of the files to Flow.
  Object.entries(files).forEach(([fieldId, fieldFiles]) => {
    fieldFiles.forEach(({ file, uploaded }) => {
      uploadingFiles.push({ file, fieldId });

      if (!uploaded) {
        flow.addFile(file);
      }
    });
  });

  // Start the upload.
  flow.upload();
};

/**
 * Obtain the form associated with the given assignment.
 * @param assignment
 */
export const getFormOfAssignment = (assignment: Assignment) => {
  // If the assignment has no form, but has an associated container
  // we fallback to the form of the container.
  if (assignment && !assignment.form && assignment.assignmentContainer) {
    return assignment.assignmentContainer.form;
  }

  return assignment?.form;
};

export const getReadableAccessTokenStatus = (
  token: AssignmentAccessToken,
): string => {
  switch (token.status) {
    case 'new':
      return 'nieuw';
    case 'used':
      return 'bezig';
    case 'done':
      return 'gereed';
    default:
      throw Error('Unsupported status');
  }
};
