import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Grid, Theme } from '@material-ui/core';

import { EPAAssignment } from '../../../types';
import { colors } from '../../../config/theme';
import ProductViewDialog from '../portfolio/ProductViewDialog';
import AssignmentCollaborationButton from './AssignmentCollaborationButton';
import AssignmentReviewBadge from './AssignmentReviewBadge';
import AssignmentValuation from './AssignmentValuation';

interface EPAAssignmentRowProps {
  assignment: EPAAssignment;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(2),
    borderBottom: `1px solid ${colors.primary.ultraLight}`,
  },
  button: {
    fontSize: 'inherit',
  },
  assignment: {
    display: 'flex',
    alignItems: 'center',
    width: '30%',
  },
  assignedLevel: {
    width: '30%',
  },
  valuation: {
    width: '30%',
  },
  actions: {
    width: '10%',
  },
}));

const EPAAssignmentRow = (props: EPAAssignmentRowProps) => {
  const { assignment } = props;
  const history = useHistory();
  const classes = useStyles();

  const [dialogLoaded, setDialogLoaded] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (dialogOpen) {
      setDialogLoaded(true);
    }
  }, [dialogOpen]);

  const handleEdit = () => {
    history.push(`/onderwijs/opdracht/${assignment.id}`);
  };

  const handleOpen = () => {
    if (assignment.editable) {
      handleEdit();
      return;
    }

    setDialogOpen(true);
  };
  const handleClose = () => setDialogOpen(false);

  return (
    <div className={classes.root}>
      <div className={classes.assignment}>
        {dialogLoaded && (
          <ProductViewDialog
            product={assignment}
            onClose={handleClose}
            open={dialogOpen}
          />
        )}
        <Button className={classes.button} onClick={handleOpen}>
          {assignment.name}
        </Button>
        {assignment.inReview && (
          <Box ml={1}>
            <AssignmentReviewBadge assignment={assignment} />
          </Box>
        )}
      </div>
      <div className={classes.assignedLevel}>
        {assignment.state === 'finished'
          ? `Niveau ${assignment.assignedLevel} door beoordelaar`
          : null}
      </div>
      <div className={classes.valuation}>
        <AssignmentValuation assignment={assignment} />
      </div>
      <div className={classes.actions}>
        <div style={{ float: 'right' }}>
          <Grid container spacing={1}>
            {assignment.form && assignment.form.externalFields && (
              <AssignmentCollaborationButton assignment={assignment} />
            )}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default EPAAssignmentRow;
