import React, { useEffect, useMemo, useState } from 'react';
import MomentUtils from '@date-io/moment';
import { Box, debounce, FormControl } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment, { Moment } from 'moment';

import { ApiFilterCriteria } from '../../types';
import FilterPopover from './FilterPopover';

interface DateFilterProps {
  field: string;
  name: string;
  onChange: (date: MaterialUiPickersDate) => void;
  criteria: ApiFilterCriteria;
  showLabel?: boolean;
}

const DateFilter = (props: DateFilterProps) => {
  const { field, name, onChange, criteria, showLabel } = props;
  const [date, setDate] = useState<Moment | null>(null);
  const [currentCriteria, setCurrentCriteria] =
    useState<ApiFilterCriteria | null>(null);

  const doDateChange = debounce((date: MaterialUiPickersDate) => {
    setDate(date);
    onChange(date);
  }, 800);

  const handleDateChange = (date: MaterialUiPickersDate) => doDateChange(date);

  /**
   * Handle initial load.
   */
  useEffect(() => {
    // If the criteria did not change, don't run update.
    if (
      criteria !== null &&
      JSON.stringify(criteria) === JSON.stringify(currentCriteria)
    ) {
      return;
    }

    const filterValue = criteria.filters ? criteria.filters[field] : null;

    if (filterValue && typeof filterValue === 'string') {
      setDate(moment(filterValue));
    } else {
      setDate(null);
    }

    setCurrentCriteria(criteria);
  }, [criteria]);

  const hasFilters = useMemo(() => {
    return date !== null;
  }, [date]);

  return (
    <FilterPopover name={name} showLabel={showLabel} hasFilters={hasFilters}>
      <Box pl={2} pr={2}>
        <FormControl fullWidth margin="normal">
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              disableToolbar
              format="DD-MM-YYYY"
              orientation="landscape"
              autoOk
              margin="normal"
              label="Datum"
              value={date}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'Verander datum',
              }}
            />
          </MuiPickersUtilsProvider>
        </FormControl>
      </Box>
    </FilterPopover>
  );
};

export default DateFilter;
