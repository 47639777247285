import {
  ApiFilterCriteria,
  AssignmentAccessTokenWithAssignment,
  CollectionResponse,
  Repository,
} from '../../types';
import ApiClient from '../../api/ApiClient';
import { parseCriteria } from '../../utils/common';

class ContributionRequestsRepository
  implements Repository<AssignmentAccessTokenWithAssignment>
{
  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
  ): Promise<CollectionResponse<AssignmentAccessTokenWithAssignment>> {
    const params = parseCriteria(criteria, { page: page || 1 });

    return ApiClient.get('/api/my-contribution-requests', params);
  }
}

export default ContributionRequestsRepository;
