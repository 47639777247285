import {
  ApiFilterCriteria,
  DataCollection,
  Module,
  Repository,
} from '../../../types';
import { FilterColumnOption } from '../../../components/filter-column/types';

class ModuleCollectionRepository implements Repository<Module> {
  private modules: Module[];

  constructor(modules: Module[]) {
    this.modules = modules;
  }

  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
  ): Promise<DataCollection<Module>> {
    return new Promise<DataCollection<Module>>((resolve) => {
      const perPage = 10;
      const start = ((page || 1) - 1) * perPage;

      const modules: Module[] = this.modules
        .sort((a, b) => {
          if (criteria && criteria.order) {
            for (const { field, order } of criteria.order) {
              if (field === 'publishedName') {
                const compare = a.publishedName.localeCompare(b.publishedName);

                return order === 'asc' ? compare : -compare;
              }
            }
          }

          return a.publishedName.localeCompare(b.publishedName);
        })
        .filter((m) => {
          // Remove all non-IO modules.
          if (m.type !== 'IO') {
            return false;
          }

          // No criteria defined, skip filtering.
          if (!criteria) {
            return true;
          }

          const { query } = criteria;

          if (
            query &&
            !m.publishedName.toLowerCase().includes(query.toLowerCase())
          ) {
            return false;
          }

          if (criteria.filters) {
            const {
              status,
            }: {
              status?: FilterColumnOption[];
            } = criteria.filters;

            if (
              m.status &&
              status &&
              !status.find((o) => o.value === m.status)
            ) {
              return false;
            }
          }

          return true;
        });

      resolve({
        items: modules.slice(start, start + perPage),
        totalItems: modules.length,
        perPage,
      });
    });
  }
}

export default ModuleCollectionRepository;
